'use client'

import { Tab, useStoreState, useTabContext, type TabProps } from '@ariakit/react'
import Button, { type ButtonProps } from '../Button/Button'
import { useId } from 'react'

const defaultStyle = {
  flexGrow: 1,
  flexBasis: 0,
  minWidth: '7rem',
  minHeight: 32,
}
/** Use instead of the default Ariakit <Tab /> component, which doesn't have proper selected styles */
export default function TabButton ({ children, ...props }: ButtonProps & TabProps) {
  const _id = useId()
  const id = props.id || _id

  const store = useTabContext()
  const selected = useStoreState(store, 'selectedId')
  const isSelected = selected === id

  const activeStyle = isSelected ? { backgroundColor: 'rgb(255, 255, 255, .75)', boxShadow: '0px 2px 7px -2px rgb(0 0 0 / 0.14)' } : undefined

  return (
    <Tab
      id={id}
      style={{...defaultStyle, ...activeStyle }}
      render={<Button variant={isSelected ? 'raised' : 'flat' } {...props} />}
    >
      {children}
    </Tab>
  )
}
