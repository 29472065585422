import Text, { type TextProps } from '../Text/Text'
import * as styles from './Heading.css'

interface HeadingProps extends TextProps {
  h: 1 | 2 | 3 | 4 | 5 | 6
}

export default function Heading ({
  children,
  h,
  className = '',
  ...props
}: HeadingProps) {
  return (
    <Text
      {...props}
      as={`h${h}`}
      className={`${styles[`h${h}`]} ${className}`}
    >
      {children}
    </Text>
  )
}
