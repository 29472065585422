import Text from '@/components/Text/Text'
import { TooltipTrigger, TooltipContent, Tooltip } from '@/components/Tooltip/Tooltip'
import { Row } from '@christiankaindl/lyts'
import { Fragment } from 'react'

const courts = [
  {
    name: 'OGH',
    fullName: 'Oberster Gerichtshof',
  },
  {
    name: 'VfGH',
    fullName: 'Verfassungsgerichtshof',
  },
  {
    name: 'VwGH',
    fullName: 'Verwaltungsgerichtshof',
  },
  {
    name: 'DSB',
    fullName: 'Datenschutzbehörde',
  },
  {
    name: 'BVwG',
    fullName: 'Bundesverwaltungsgericht',
  },
  {
    name: 'BG',
    fullName: 'Bezirksgerichte',
  },
  {
    name: 'LG',
    fullName: 'Landesgerichte',
  },
  {
    name: 'AUSL',
    fullName: 'Ausländische Gerichte',
  },
  {
    name: 'BFG',
    fullName: 'Bundesfinanzgericht',
  },
]

export default function CourtsList () {
  return (
    <Row gap={0.5} wrap>
      {courts.map((court, index) => {
        return (
          <Fragment key={court.name}>
            {index > 0 && '·'}
            <Tooltip key={court.name} timeout={50}>
              <TooltipTrigger>
                <Text as='span' color='tertiary' style={{ cursor: 'default' }}>
                  {court.name}
                </Text>
              </TooltipTrigger>
              <TooltipContent>
                <Text>
                  {court.fullName}
                </Text>
              </TooltipContent>
            </Tooltip>
          </Fragment>
        )
      })}
    </Row>
  )
}
