'use client'

import * as styles from './Tooltip.css'
import { TooltipAnchor, TooltipProvider, Tooltip as TooltipContentBase, type TooltipProps, type TooltipAnchorProps, type TooltipProviderProps } from '@ariakit/react'

export function Tooltip ({ children, placement = 'bottom', timeout = 300, ...props }: TooltipProviderProps) {
  return (
    <TooltipProvider placement={placement} timeout={timeout} {...props}>
      {children}
    </TooltipProvider>
  )
}

export function TooltipContent ({ children, unmountOnHide = true, ...props }: TooltipProps) {
  return (
    <TooltipContentBase gutter={6} unmountOnHide={unmountOnHide} {...props} className={styles.tooltipContent}>
      {children}
    </TooltipContentBase>
  )
}

export function TooltipTrigger ({ children, ...props }: TooltipAnchorProps) {
  return (
    <TooltipAnchor {...props}>
      {children}
    </TooltipAnchor>
  )
}
