'use client'

import { TabList as BaseTabList, type TabListProps } from '@ariakit/react'
import { theme } from '@/styles/theme.css'
import { Row } from '@christiankaindl/lyts'

/** Use instead of the default Ariakit <TabList /> component */
export default function TabList ({ children, ...props }: TabListProps) {
  return (
    <BaseTabList
      {...props}
      style={{ backgroundColor: theme.colors.backgrounds.bgA2, borderRadius: 11, padding: 4, width: 'min-content', ...props.style }}
      render={<Row gap='2px' />}
    >
      {children}
    </BaseTabList>
  )
}
