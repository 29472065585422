'use client'

import { type ReactNode } from 'react'
import { SWRConfig } from 'swr'
import { MediaContextProvider } from '../MediaQuery/MediaQuery'
import { ClerkProvider } from '@clerk/nextjs'
import { deDE } from '@clerk/localizations'

const fetcher = async url => await fetch(url).then(async r => await r.json())

export default function Providers ({ children }: { children: ReactNode }) {
  return (
    <SWRConfig value={{ fetcher }}>
      <MediaContextProvider>
        <ClerkProvider
          localization={{
            ...deDE,
            socialButtonsBlockButtonManyInView: '{{provider|titleize}}',
          }}
          supportEmail='hallo@gesetzefinden.at'
          appearance={{
            layout: {
              termsPageUrl: '/agb',
              privacyPageUrl: '/datenschutz',
            },
          }}
        >
          {children}
        </ClerkProvider>
      </MediaContextProvider>
    </SWRConfig>
  )
}
