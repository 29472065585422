'use client'

import { Columns, Row, Stack } from '@christiankaindl/lyts'
import SubmitButton from './SubmitButton'
import Text from '@/components/Text/Text'
import Link from 'next/link'
import { useActionState } from 'react'
import { handleSubmit } from './createContact'
import InputRow from '@/components/Input/InputRow/InputRow'

export default function WaitlistForm () {
  const [state, formAction] = useActionState(handleSubmit, {})
  // Log submission event to Matomo

  if (state.success) {
    return (
      <Stack asChild gap={1.5}>
        <Text>
          Vielen Dank für deine Anmeldung! Wir treten in Kürze mit dir in Kontakt.
        </Text>
      </Stack>
    )
  } else if (state.error) {
    return (
      <Text>
        Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal oder kontaktiere uns direkt per Email: <Link href='/kontakt'>Kontakt</Link>
      </Text>
    )
  }

  return (
    <Stack asChild gap={1.5}>
      <form action={formAction}>
        <Columns collapseAt='20em'>
          <InputRow
            label='Vorname'
            name='firstName'
            required
          />
          <InputRow
            label='Nachname'
            name='lastName'
            required
          />
        </Columns>
        <InputRow
          label='Email Adresse'
          name='email'
          required
        />
        <Stack gap={0.25}>
          <InputRow
            label='Rechtsgebiete'
            name='legalCategories'
            required
          />
          <Text style={{ paddingTop: 3 }} size='small' color='secondary'>Beispiele: Arbeitsrecht, Datenschutzrecht, Versicherungsrecht, Bankenrecht, Vertragsrecht, Asylrecht</Text>
        </Stack>
        <InputRow
          label='Kanzlei oder Unternehmen (Optional)'
          name='company'
        />

        <Row asChild gap={0.5}>
          <label>
            <input required type="checkbox" />
            <Text>
              Hiermit akzeptiere ich die <Link href='/agb' target='_blank'>AGB</Link> und nehme die <Link href='/datenschutz' target='_blank'>Datenschutzerklärung</Link> zur Kenntnis.
            </Text>
          </label>
        </Row>

        <Stack gap={0.5}>
          <SubmitButton />
        </Stack>
      </form>
    </Stack>
  )
}
