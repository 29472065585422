'use client'

import { useAuth } from "@clerk/nextjs"

export function SignedIn ({ children }: { children: React.ReactNode }) {
  const auth = useAuth()

  if (!auth.isLoaded) return null
  if (!auth.isSignedIn) return null

  return (
    <div className="fade-in">
      {children}
    </div>
  )
}

export function SignedOut ({ children }: { children: React.ReactNode }) {
  const auth = useAuth()

  if (!auth.isLoaded) return null
  if (auth.isSignedIn) return null

  return (
    <div className="fade-in">
      {children}
    </div>
  )
}
