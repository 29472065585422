'use client'

import { type FunctionComponent, isValidElement, type ReactElement, Fragment } from 'react'
import Heading from '../Heading/Heading'
import { Minus, Plus } from 'lucide-react'
import Text from '../Text/Text'
import { Row, Stack } from '@christiankaindl/lyts'
import {
  Disclosure,
  DisclosureContent,
  useDisclosureStore,
} from '@ariakit/react'
import { type RechtssatzNorm } from '@/components/Search/Result'
import RefButton from '@/components/Norm/RefButton'
import { Author } from '../../app/blog/Author'

export interface FaqItemProps {
  question: string
  answer: string[] | string | ReactElement<any>
  defaultOpen: boolean
  id?: string
  sources?: RechtssatzNorm[]
  authors?: any[]
}
const FaqItem: FunctionComponent<FaqItemProps> = function FaqItem ({ answer, question, defaultOpen, id, sources, authors }) {
  const disclosure = useDisclosureStore({ defaultOpen })
  const isOpen = disclosure.useState('open')

  return (
    <Stack id={id}>
      <Disclosure render={<Row xAlign='space-between' />} store={disclosure} style={{ padding: '1em 0', cursor: 'pointer' }}>
        <Heading h={4}>
          {question}
        </Heading>
        {isOpen && (<Minus size={24} color='rgb(181, 156, 113)' />)}
        {!isOpen && (<Plus size={24} color='rgb(181, 156, 113)' />)}
      </Disclosure>
      <DisclosureContent render={<Stack />} store={disclosure}>
        {typeof answer === 'string' && (
          <Text style={{ whiteSpace: 'break-spaces' }}>
            {answer}
          </Text>
        )}
        {Array.isArray(answer) && answer && (
          answer.map((text, index) => {
            if (isValidElement(text)) {
              return (
                <Fragment key={index}>
                  {text}
                </Fragment>
              )
            }
            return (
              <Text key={index}>
                {text}
              </Text>
            )
          })
        )}
        {isValidElement(answer) && (
          answer
        )}
        {sources && sources.length > 0 && (
          <Row gap={0.5} wrap>
            <span>Quelle{sources.length === 1 ? '' : 'n'}:</span>
            {sources.map((source) => {
              return (
                // @ts-expect-error types are wrong
                (<RefButton key={source.id} {...source} id={source.citation} />)
              )
            })}
          </Row>
        )}
        {authors && authors.length > 0 && (
          <Row gap={0.5} wrap>
            {authors.map((author) => {
              return <Author key={author.id} author={author} />
            })}
          </Row>
        )}
      </DisclosureContent>
    </Stack>
  )
}

export default FaqItem
