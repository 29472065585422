'use client'

import * as styles from './Menu.css'
import type { ButtonProps } from '@/components/Button/Button'
import * as AriakitMenu from '@ariakit/react/menu'
import Button from '@/components/Button/Button'

export function MenuList ({ children, ...props }: AriakitMenu.MenuProps) {
  return (
    <AriakitMenu.Menu unmountOnHide portal {...props} className={styles.content} gutter={6}>
      {children}
    </AriakitMenu.Menu>
  )
}

export function MenuButton ({ children, ...props }: AriakitMenu.MenuButtonProps & ButtonProps) {
  return (
    <AriakitMenu.MenuButton render={<Button variant={props.variant ?? 'flat'} />} className={`${styles.trigger} ${props.className ?? ''}`} {...props}>
      {children}
    </AriakitMenu.MenuButton>
  )
}

export function Menu (props: AriakitMenu.MenuProviderProps) {
  return (
    <AriakitMenu.MenuProvider {...props} />
  )
}

export default Menu
