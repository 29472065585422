import { useScroll } from 'framer-motion'
import { startTransition, useEffect, useState } from 'react'

const offsetVar = '--mobile-search-bottom-offset'
/** Only relevant for mobile search bar */
export function useSearchBottomOffset (offset: string): void {
  useEffect(() => {
    window.requestAnimationFrame(() => {
      document.querySelector('html')?.style.setProperty(offsetVar, offset)
    })
    return () => { document.querySelector('html')?.style.removeProperty(offsetVar) }
  }, [offset])
}

export function useIsTop (yThreshold: number = 300) {
  const [isTop, setIsTop] = useState(true)
  const { scrollY } = useScroll()
  useEffect(() => {
    function checkIfAtTop (y: number) {
      startTransition(() => {
        if (y < yThreshold) {
          // On mobile a scroll event is triggered when a body scroll lock is active
          // So when an overlay is open the navbar would go transparent even though the page is not scrolled to the top
          // To fix this we skip the top check while a lock is active
          if (document.body.hasAttribute('data-dialog-prevent-body-scroll')) {
            return
          }
          setIsTop(true)
        } else if (y >= yThreshold) {
          setIsTop(false)
        }
      })
    }
    return scrollY.on('change', checkIfAtTop)
  }, [scrollY, yThreshold])

  return isTop
}
