'use client'

import { LayoutDashboardIcon, LogOutIcon, UserCogIcon } from 'lucide-react'
import { Menu, MenuButton, MenuItem, MenuList } from '../Menu'
import Image from 'next/image'
import { useUser } from '@clerk/nextjs'

export default function SignedInUserButton () {
  const _user = useUser()
  const user = _user.user
  
  if (!user) {
    return null
  }

  return (
    <Menu>
      <MenuButton size='small' circular square style={{ color: '#A48757', backgroundColor: 'rgba(228, 177, 95, 0.2)' }}>
        <Image src={user.imageUrl} alt='User profile image' height={28} width={28} />
      </MenuButton>
      <MenuList>
        <MenuItem href='/dashboard'>
          <LayoutDashboardIcon size={16} />
          Dashboard
        </MenuItem>
        <MenuItem href='/dashboard/profil'>
          <UserCogIcon size={16} />
          Profil
        </MenuItem>
        {/* {user.orgs.length > 0 && (
          <MenuItem href='/dashboard/organization'>
            <BuildingIcon size={16} />
            Organisation
          </MenuItem>
        )} */}
        <MenuItem href='/logout'>
          <LogOutIcon size={16} />
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
