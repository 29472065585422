'use client'

import { type CSSProperties, type ReactNode, useRef, useState, useEffect, useLayoutEffect } from 'react'
import { Desktop } from '../MediaQuery/MediaQuery'
import { motion, useMotionValueEvent, useScroll } from 'framer-motion'
import { theme } from '@/styles/theme.css'
import Button from '../Button/Button'
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'
import { Row } from '@christiankaindl/lyts'
import * as styles from './Reel.css'

const transition = { visualDuration: 0.3, bounce: 0.1, type: 'spring' }
export default function Reel ({ children, style = {}, innerStyle = {}, ...props }: { children?: ReactNode, style?: CSSProperties, innerStyle?: CSSProperties }) {
  const ref = useRef<HTMLDivElement>(null)
  const scroll = useScroll({ container: ref })
  const [arrows, setArrows] = useState<'both' | 'left' | 'right' | 'none'>('right')

  function updateArrows (value: number) {
    if (!ref.current) return

    if (value > 8 && value < (ref.current.scrollWidth - ref.current.clientWidth - 8)) {
      setArrows('both')
    } else if (value > 8) {
      setArrows('left')
    } else if (value < (ref.current.scrollWidth - ref.current.clientWidth - 8)) {
      setArrows('right')
    } else {
      setArrows('none')
    }
  }

  useLayoutEffect(() => {
    if (!ref.current) return
    ref.current.style.setProperty('--reel-mask-right', arrows === 'both' || arrows === 'right' ? null : '0')
    ref.current.style.setProperty('--reel-mask-left', arrows === 'both' || arrows === 'left' ? null : '0')
  }, [arrows])

  useMotionValueEvent(scroll.scrollX, 'change', (value) => {
    updateArrows(value)
  })

  useEffect(() => {
    updateArrows(scroll.scrollX.get())
  }, [ref, scroll.scrollX])

  function nextItem () {
    ref.current?.scrollBy({ left: 300, behavior: 'smooth' })
  }
  function prevItem () {
    ref.current?.scrollBy({ left: -300, behavior: 'smooth' })
  }

  const showLeftArrow = arrows === 'both' || arrows === 'left'
  const showRightArrow = arrows === 'both' || arrows === 'right'

  return (
    <div
      style={{
        marginLeft: '-1.5em',
        marginRight: '-1.5em',
        backgroundColor: theme.colors.backgrounds.bgA1,
        borderRadius: 24,
        position: 'relative',
        ...style,
      }}
      {...props}
    >
      <Desktop>
        <motion.div style={{ position: 'absolute', left: 9, zIndex: 10, top: '50%', y: '-50%' }} transition={transition} initial={{ scale: 0 }} animate={showLeftArrow ? { scale: 1 } : undefined}>
          <Button onClick={() => { prevItem() }} circular square size='large' style={{ backdropFilter: 'blur(10px)' }}>
            <ChevronLeftIcon size={20} />
          </Button>
        </motion.div>
        <motion.div style={{ position: 'absolute', right: 9, zIndex: 10, top: '50%', y: '-50%' }} transition={transition} initial={{ scale: 0 }} animate={showRightArrow ? { scale: 1 } : undefined}>
          <Button onClick={() => { nextItem() }} circular square size='large' style={{ backdropFilter: 'blur(10px)' }}>
            <ChevronRightIcon size={20} />
          </Button>
        </motion.div>
      </Desktop>
      <Row
        className={styles.root}
        style={{
          // @ts-expect-error CSS custom var
          '--reel-mask-right': 0,
          '--reel-mask-left': 0,
          ...innerStyle,
        }}
        gap={1.5}
        yAlign='start'
        ref={ref}
      >
        {children}
      </Row>
    </div>
  )
}
