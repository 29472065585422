'use client'

import { Stack } from '@christiankaindl/lyts'
import { type ReactNode, useState, useEffect, useTransition } from 'react'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/Tooltip/Tooltip'
import Text from '@/components/Text/Text'
import { useStoreState, useTooltipStore, type TooltipProps } from '@ariakit/react'
import LabelWithIcon from '../LabelWithIcon'
import { ErrorBoundary } from 'react-error-boundary'
import Placeholder from '../Placeholder/Placeholder'
import { retrievePreviewAction } from './retrievePreviewAction'
import { useRouter } from 'next/navigation'

interface ClausePreviewProps {
  children: ReactNode
  documentType?: string
  render?: TooltipProps['render']
  id?: string // is abbreviation
  normId?: string // fallback for abbreviation
}

export default function HoverPreview ({ children, render, id, normId }: ClausePreviewProps) {
  const tooltip = useTooltipStore()
  const open = useStoreState(tooltip, 'open')

  const [data, setData] = useState<any>(null)
  const [isPending, startTransition] = useTransition()
  const [isError, setIsError] = useState(false)
  useEffect(() => {
    if (data || isError) return
    if (!open) return
    if (isPending) return

    if (id ?? normId) {
      startTransition(async () => {
        try {
          // @ts-expect-error needs fix
          const _data = await retrievePreviewAction(id ?? normId)
          setData(_data)
        } catch (error) {
          console.error(error)
          setIsError(true)
        }
      })
    }
  }, [isPending, isError, open, data, id, normId])

  const router = useRouter()

  return (
    <ErrorBoundary fallback={children}>
      <Tooltip timeout={100} placement='bottom-start' store={tooltip}>
        <TooltipTrigger
          render={render ?? <span />}
          onClick={(event) => {
            // @ts-expect-error href is a string
            if (data?.snippet && !event.metaKey && !event.ctrlKey && event.target.href) {
              event.preventDefault()
              // remove the last character from the snippet ("…") so it matches the text on the new page.
              data.snippet = data.snippet.slice(0, -1)
              // @ts-expect-error href is a string
              router.push(event.target.href as string + `#text=${encodeURIComponent(data.snippet)}`)
            }
          }}
        >
          {children}
        </TooltipTrigger>
        <TooltipContent gutter={3} style={{ maxWidth: '24em', padding: 15, contain: 'layout', textAlign: 'left', borderRadius: 12, width: '100%' }}>
          {isPending && (
            <Stack gap={0.5}>
              <Placeholder type='text' />
              <Placeholder type='text' />
            </Stack>
          )}
          {isError && (
            <Text>
              Ein Fehler ist aufgetreten, versuche es später nocheinmal.
            </Text>
          )}
          {data && (
            <ErrorBoundary fallbackRender={() => JSON.stringify({ id }, null, 2)}>
              <Stack gap={0.5}>
                <LabelWithIcon
                  size='small'
                  text={data.abbreviation ?? data.lawTitle ?? data.Geschaeftszahl ?? data.Rechtssatznummer}
                  iconType={(data.documentType === 'law' || data.documentType === 'clause') ? 'law' : 'decision'}
                />
                {(data.documentType === 'law' || data.documentType === 'clause') && (
                  <Text maxLines={2} weight='bold' style={{ lineHeight: 1.3 }}>
                    {(data.enumeration && data.enumeration !== '§ 0') ? <>{data.enumeration}&ensp;</> : ''}{data.title}
                  </Text>
                )}
                {(data.content ?? data.snippet) && (
                  <Text maxLines={4} color='secondary' style={{ lineHeight: 1.3 }}>
                    <span style={{ color: 'black' }}>{data.para ? `Abs ${data.para as string}${data.z ? ` Z ${data.z as string}` : ''}: ` : null}</span>{data.content ?? data.snippet}
                  </Text>
                )}
              </Stack>
            </ErrorBoundary>
          )}
        </TooltipContent>
      </Tooltip>
    </ErrorBoundary>
  )
}
