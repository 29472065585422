import { Row, type RowProps } from '@christiankaindl/lyts'
import { type ReactNode, type CSSProperties } from 'react'
import * as styles from './Pill.css'

interface Props extends RowProps {
  gap?: number
  children?: ReactNode
  style?: CSSProperties
}
export default function Pill ({
  children,
  gap = 0.5,
  ...props
}: Props) {
  return (
    <Row
      gap={gap}
      {...props}
      className={`${styles.root}${props.className ? ` ${props.className}` : ''}`}
    >
      {children}
    </Row>
  )
}
