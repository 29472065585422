'use client'

import Heading from '@/components/Heading/Heading'
import Page from '@/components/Page/Page'
import Pill from '@/components/Pill/Pill'
import Text from '@/components/Text/Text'
import { Stack } from '@christiankaindl/lyts'
import Link from 'next/link'
import { getMetadata } from '@/components/Page/getMetadata'
import { useEffect } from 'react'
import { sendSlackMessageFrontendError } from '../lib/sendSlackMessage'
import { isDev } from '@/lib/utils'

export const metadata = getMetadata({
  title: '500 - Ein Fehler ist aufgetreten',
}, '')

export default function Error ({
  error,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    console.log('sending slack message to channel...', process.env.NEXT_PUBLIC_VERCEL_ENV)
    // We don't want to send messages when developing locally
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'development') return

    sendSlackMessageFrontendError(`${isDev ? '[DEV]' : ''} Error: ${error.message}\n\ndigest: ${error.digest as string}\n\n${error.stack as string}`)
      .catch((error) => { console.error('Failed to send message:', error) })
  }, [error])

  return (
    <Page footer={false}>
      <Stack xAlign='center' yAlign='center' style={{ padding: 21, minHeight: '80vh' }}>
        <Heading h={1}>
          Ein Fehler ist aufgetreten <Pill style={{ fontWeight: 400, fontFamily: 'var(--inter-font)' }}>500</Pill>
        </Heading>

        <Text color='secondary' align='center'>
          Das GesetzeFinden.at-Team wurde informiert.<br />Falls dieser Fehler öfter auftritt, <Link href='/kontakt'>kontaktiere uns</Link>
        </Text>
      </Stack>
    </Page>
  )
}
