import { Stack } from '@christiankaindl/lyts'
import { type CSSProperties, type Ref } from 'react'
import * as styles from './Placeholder.css'

interface PlaceholderProps {
  type?: 'text' | 'card' | 'image' | 'absolute'
  aspectRatio?: string
  height?: CSSProperties['height']
  width?: CSSProperties['width']
  style?: CSSProperties
  numLines?: number
  ref?: Ref<HTMLDivElement>
}
export default function Placeholder ({ type = 'text', aspectRatio, style = {}, numLines = 1, width, height, ref }: PlaceholderProps) {
  const widths = Array.from({ length: numLines }, (_, index) => 7 + (index % 3))

  if (type === 'image') {
    return (
      <div className={styles.root} style={{ ...style, width, height, aspectRatio }} ref={ref} />
    )
  } else if (type === 'text') {
    return (
      <Stack gap={0.5}>
        {widths.map((width, index) => {
          return (
            <div className={styles.root} key={index} style={{ height: '1.5em', width: `${width}em` }} />
          )
        })}
      </Stack>
    )
  }

  return null
}
